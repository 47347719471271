<template>
  <v-card class="rounded-0" :height="cardheight">
    <v-img
      :src="image"
      class="white--text align-center"
      :gradient="isoverlay ? 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)' : ''"
      height="100%"
    >
      <v-card-title>
        <v-row align="center" justify="center">
          <v-col class="text-center" cols="12">
            <h1 class="display-1 text-uppercase font-weight-thin">
              {{ tagline }}
            </h1>
            <p class="subtitle-1">
              {{ subtitle }}
            </p>
          </v-col>
        </v-row>
      </v-card-title>
    </v-img>
  </v-card>
</template>

<script>
export default {
  props: ['tagline', 'subtitle', 'image', 'isoverlay', 'cardheight'],
};
</script>

<style></style>
